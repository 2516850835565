import { isTouchDevice } from './helpers'

(() => {
	/* eslint no-restricted-syntax: ["error"] */

	if (isTouchDevice()) {
		return
	}

	const gallery = document.querySelector('.photo-gallery')
	const elements = gallery.querySelectorAll('.photo-gallery__item')
	const preventClick = (e) => {
		e.preventDefault()
		e.stopImmediatePropagation()
	}
	let isDown = false
	let isDragged = false
	let startX
	let scrollLeft

	gallery.addEventListener('mousedown', e => {
		isDown = true
		gallery.classList.add('active')
		startX = e.pageX - gallery.offsetLeft
		scrollLeft = gallery.scrollLeft
	})

	gallery.addEventListener('mouseleave', () => {
		isDown = false
		gallery.classList.remove('active')
	})

	gallery.addEventListener('mouseup', () => {
		isDown = false
		if (isDragged) {
			for (let i = 0; i < elements.length; i++) {
				elements[i].addEventListener('click', preventClick)
			}
		} else {
			for (let i = 0; i < elements.length; i++) {
				elements[i].removeEventListener('click', preventClick)
			}
		}
		gallery.classList.remove('active')
		isDragged =  false
	})

	gallery.addEventListener('mousemove', e => {
		if (!isDown) return
		isDragged =  true
		e.preventDefault()
		const x = e.pageX - gallery.offsetLeft
		const walk = (x - startX) * 2.5
		gallery.scrollLeft = scrollLeft - walk
	})
})()
