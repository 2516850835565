
/**
 * @param color chroma.Color
 * @returns string e.g. '90deg, 50%, 50%'
 */
export function chromaColorToHslValues(color)
{
	let hsl = color.css('hsl')
	const sep = hsl.indexOf(",") > -1 ? "," : " "
	hsl = hsl.substr(4).split(")")[0].split(sep)
	let h = hsl[0]
	const s = hsl[1].substr(0,hsl[1].length - 1) / 100
	const l = hsl[2].substr(0,hsl[2].length - 1) / 100
	if (h.indexOf("deg") > -1)
		h = h.substr(0,h.length - 3)
	else if (h.indexOf("rad") > -1)
		h = Math.round(h.substr(0,h.length - 3) * (180 / Math.PI))
	else if (h.indexOf("turn") > -1)
		h = Math.round(h.substr(0,h.length - 4) * 360)
	if (h >= 360)
		h %= 360
	return Math.round(h) + 'deg, '
		+ Math.round(s * 100) + '%, '
		+ Math.round(l * 100) + '%'
}

export function invertHex(hex)
{
	return '#' + (Number(`0x1${hex.replace('#', '')}`) ^ 0xffffff).toString(16).substr(1)
}

export function setRootProperty(name, value)
{
	document.querySelector(':root').style.setProperty(name, value)
}

export function getRootPropertyValue(name)
{
	return document.querySelector(':root').style.getPropertyValue(name)
}

export function isTouchDevice()
{
	return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0)
}
